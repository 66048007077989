import React, { useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AuthService from "../services/auth.service";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import logo1 from '../resources/logo1.png';
import Fab from '@mui/material/Fab';
import PropTypes from "prop-types";

const Login = (props) => {
	const navigate = useNavigate();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [drawerRecSenha, setDrawerRecSenha] = useState(false);
	const [usernameRec, setUsernameRec] = useState("");

	const toggleDrawer = (open) => (event) => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setDrawerRecSenha(open);
	};

	const onChangeUsername = (e) => {
		const username = e.target.value;
		setUsername(username);
	};

	const onChangePassword = (e) => {
		const password = e.target.value;
		setPassword(password);
	};

	const handleLogin = (e) => {
		e.preventDefault();

		setMessage("");
		setLoading(true);

		AuthService.login(username, password).then((res) => {
				if (res === true) {
					navigate('/', { replace: true });
					window.location.reload();
				}
				else 
				{
					setMessage("Usuário e/ou senha inválidos!");
				}
				setLoading(false);
			}, () => {
				setMessage("Não foi possível efetuar login.");
				setLoading(false);
			}
			
		);
	};

	const handleRecuperarSenha = () => {
		if (usernameRec.length < 1) {
			return props.OpenSnackbar("É necessário preencher um e-mail.");
		}

		setLoading(true);

		AuthService.recuperarSenha(usernameRec).then((res) => {
			if (res === "sucesso") {
				props.OpenSnackbar("Um e-mail de recuperação foi enviado. Verifique a sua caixa de entrada.");
			} else {
				props.OpenSnackbar("Não foi possível recuperar a conta.");
			}

			setLoading(false);
			setUsernameRec("");
			toggleDrawer(false);
		});
	};

	return (
		<React.Fragment>
			<SwipeableDrawer
				anchor={'bottom'}
				open={drawerRecSenha}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
			>
				<Container component="main" maxWidth="xs">
					<Box
						sx={{
							marginTop: 10,
							marginBottom: 10,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Typography component="h1" variant="h5" sx={{ mb: 1 }}>
							Recuperação de Senha
						</Typography>

						<TextField
							fullWidth
							color="primary"
							size="normal"
							margin="normal"
							value={usernameRec}
							onChange={(event) => setUsernameRec(event.target.value)}
							id="emailRec"
							placeholder="email@email.com"
							name="emailRec"
							autoComplete="email"
							autoFocus
						/>

						<Button
							type="submit"
							variant="success"
							sx={{ mt: 3, mb: 2 }}
							onClick={() => handleRecuperarSenha()}
						>
							Recuperar
						</Button>
					</Box>
				</Container>
			</SwipeableDrawer>

			<Container component="main" maxWidth="xs">
				<Box
					sx={{
						marginTop: 8,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Box
						component="img"
						sx={{
							width: "253px",
							height: "192px",
							m: 1
						}}
						alt="logo-img"
						src={logo1}
					/>
					<Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
						<TextField
							color="primary"
							size="normal"
							margin="normal"
							required
							fullWidth
							value={username}
							onChange={onChangeUsername}
							id="email"
							placeholder="email@email.com"
							name="email"
							autoFocus
						/>
						<TextField
							color="primary"
							size="normal"
							margin="normal"
							required
							fullWidth
							value={password}
							onChange={onChangePassword}
							name="password"
							placeholder="************"
							type="password"
							id="password"
							autoComplete="current-password"
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2 }}
							disabled={loading}
						>
							Acessar
							{loading && (
								<span className="spinner-border spinner-border-sm"></span>
							)}
						</Button>
						<Fab variant="extended" size="small" sx={{ backgroundColor: 'transparent', color: 'primary.main', boxShadow: 0 }} onClick={toggleDrawer(true)}>
							Recuperar senha
						</Fab>
						<Grid container alignItems={"center"}>
							<Grid item>
								{message && (
									<Typography sx={{ mt: 2, mb: 2, color: 'warning.main' }}>{message}</Typography>
								)}
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
		</React.Fragment>
	);
};

Login.propTypes = {
	OpenSnackbar: PropTypes.func,
};

export default Login;