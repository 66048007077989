import api from "./api";

const getDocsReg = (idEmpresa, cnpj) => {
	return api.get("/Intimacoes/ConsultarDocsRegEmpresa", {
		params: {
			idEmpresa: idEmpresa,
			cnpj: cnpj
		}
	})
	.then((response) => {
		console.log("getDocsReg", response.data);
		if (response?.data?.mensagem === "sucesso") {
			return response.data.docs;
		} else {
			return undefined;
		}
	});
};

const addDocReg = (formdata) => {
	return api.post("/Intimacoes/InserirDocRegEmpresa", formdata, {
		headers: { 'Content-Type': 'multipart/form-data' }
	})
	.then((response) => {
		console.log("addDocReg", response.data);
		if (response?.data?.mensagem === "sucesso") {
			return true;
		}
		else {
			return false;
		}
	});
};

const updateDocReg = (idUsuario, idDocumento, dataVencimento, resumo) => {
	return api.post("/Intimacoes/AlterarDocReg", {
		idUsuario: idUsuario,
		idDocumento: idDocumento,
		dataVencimento: dataVencimento,
		resumo: resumo
	})
	.then((response) => {
		console.log("updateDocReg", response.data);
		return response.data?.mensagem;
	});
};

const getAnexosDoc = (idDocumento) => {
	return api.get("/Intimacoes/ConsultarAnexosDocReg", {
		params: {
			idDocumento: idDocumento
		}
	})
	.then((response) => {
		console.log("getAnexosDoc", response.data);
		if (response?.data !== null && response?.data?.anexos !== null) {
			return response.data.anexos;
		}
		return [];
	});
};

const addAnexoDoc = (formdata) => {
	return api.post("/Intimacoes/InserirAnexosDocReg", formdata, {
		headers: { 'Content-Type': 'multipart/form-data' }
	})
	.then((response) => {
		console.log("addAnexoDoc", response.data);
		if (response?.data !== null) {
			return response.data;
		}
		return undefined;
	});
};

const deleteAnexoDoc = (idArquivo, idDocumentoRegulatorio) => {
	return api.post("/Intimacoes/DeletarAnexoDocReg", {
		idArquivo: idArquivo,
		idDocumentoRegulatorio: idDocumentoRegulatorio
	})
	.then((response) => {
		console.log("deleteAnexoDoc", response.data);
		if (response?.data !== null) {
			return response.data;
		}
		return undefined;
	});
};

const getCurrentEmpresa = () => {
	return JSON.parse(localStorage.getItem("empresa"));
};

const setCurrentEmpresa = (empresa) => {
	localStorage.setItem("empresa", JSON.stringify(empresa));
};

const removeCurrentEmpresa = () => {
	localStorage.removeItem("empresa");
};

const HomeService = {
	getCurrentEmpresa,
	setCurrentEmpresa,
	removeCurrentEmpresa,
	getDocsReg,
	getAnexosDoc,
	addDocReg,
	updateDocReg,
	deleteAnexoDoc,
	addAnexoDoc
};

export default HomeService;
