import React, { useState } from "react";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AuthService from "../services/auth.service";
import { Typography } from "@mui/material";
import Button from '@mui/material/Button';
import PropTypes from "prop-types";
import IconButton from '@mui/material/IconButton';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import { useNavigate } from "react-router-dom";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';

const Profile = (props) => {
	const navigate = useNavigate();
	const [currentPass, setCurrentPass] = useState("");
	const [newPass, setNewPass] = useState("");
	const [newPass2, setNewPass2] = useState("");

	const [showPassword, setShowPassword] = React.useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleTrocarSenha = () => {
		if (currentPass.length < 1 || newPass.length < 1 || newPass2.length < 1) {
			return props.OpenSnackbar("É necessário preencher os campos obrigatórios.");
		}

		if (newPass.length < 7) {
			return props.OpenSnackbar("A nova senha precisa ter no mínimo 7 caracteres.");
		}

		if (newPass !== newPass2) {
			return props.OpenSnackbar("As senhas não conferem.");
		}

		AuthService.alterarSenha(props.currentUser.emailUsuario, currentPass, newPass).then((res) => {
			if (res === "sucesso") {
				props.OpenSnackbar("Senha alterada com sucesso!");
			} else {
				props.OpenSnackbar("Não foi possível alterar a senha. Verifique os dados preenchidos!");
			}

			setCurrentPass("");
			setNewPass("");
			setNewPass2("");
		});
	};

	const handleClickVoltar = () => {
		navigate('/');
	};

	return (
		<React.Fragment>
			<Container maxWidth="sm" sx={{ mt: '4vmin', mb: '15vmin', width: '100%' }}>
				<Grid container justifyContent="center" alignItems="center" spacing={3}>
					<Grid item xs={12}>
						<IconButton fill="primary.main" aria-label="voltar" onClick={handleClickVoltar}>
							<KeyboardReturnOutlinedIcon />
						</IconButton>
					</Grid>
					<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mb: '8vmin' }}>
						<Grid container justifyContent="center" alignItems="center" spacing={0}>
							<Grid item xs={4} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
								<Typography variant="subtitle1" sx={{ m: 0, p: 0, fontWeight: '600' }}>Email:</Typography>
							</Grid>
							<Grid item xs={8} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
								<Typography variant="subtitle1" sx={{ m: 0, p: 0 }}>{props.currentUser.emailUsuario}</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mb: '1vmin' }}>
						<Grid container justifyContent="center" alignItems="center" spacing={1}>
							<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
								<Typography variant="subtitle1" sx={{ m: 0, p: 0, fontWeight: '600' }}>Senha atual:</Typography>
							</Grid>
							<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
								<Input
									sx={{ width: '80%' }}
									color="primary"
									size="normal"
									margin="normal"
									required
									fullWidth
									name="password"
									placeholder="************"
									type={'password'}
									id="password"
									autoComplete="current-password"
									value={currentPass}
									onChange={(event) => setCurrentPass(event.target.value)}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mb: '1vmin' }}>
						<Grid container justifyContent="center" alignItems="center" spacing={1}>
							<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
								<Typography variant="subtitle1" sx={{ m: 0, p: 0, fontWeight: '600' }}>Nova senha:</Typography>
							</Grid>
							<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
								<Input
									sx={{ width: '80%' }}
									color="primary"
									size="normal"
									margin="normal"
									required
									fullWidth
									name="new-password"
									placeholder="************"
									type={showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
											aria-label="Ver"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											>
											{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									id="new-password"
									autoComplete="new-password"
									value={newPass}
									onChange={(event) => setNewPass(event.target.value)}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mb: '1vmin' }}>
						<Grid container justifyContent="center" alignItems="center" spacing={1}>
							<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
								<Typography variant="subtitle1" sx={{ m: 0, p: 0, fontWeight: '600' }}>Confirmar senha:</Typography>
							</Grid>
							<Grid item xs={12} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
								<Input
									sx={{ width: '80%' }}
									color="primary"
									size="normal"
									margin="normal"
									required
									fullWidth
									name="new-password2"
									placeholder="************"
									type={showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
											aria-label="Ver"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											>
											{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									id="new-password2"
									autoComplete="new-password"
									value={newPass2}
									onChange={(event) => setNewPass2(event.target.value)}
								/>
							</Grid>
						</Grid>
					</Grid>

					<Button
						fullWidth
						variant="contained"
						sx={{ mt: 4, mb: 2, width: "50%" }}
						onClick={handleTrocarSenha}
					>
						Alterar senha
					</Button>
				</Grid>
			</Container>
		</React.Fragment>
	);
};

Profile.propTypes = {
    OpenSnackbar: PropTypes.func,
	currentUser: PropTypes.object,
};

export default Profile;
