import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


function AppBottom() { 
    return (
		<Typography variant="body2" color="primary.main" align="center" sx={{ mt: 8, mb: 2, position: 'relative', bottom: 0, width: '100%' }}>
			{'Powered by '}
			<Link color="inherit" href="https://vexia.com.br">
				Vexia
			</Link>
		</Typography>
	);
}

export default AppBottom