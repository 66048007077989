import React from "react";
import { CircularProgress } from "@mui/material";

const styles = {
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
};

function Loader() {
  return (
    <div style={styles.center}>
      <CircularProgress />
    </div>
  );
}

export default Loader;
