import React, { useState, useEffect, useCallback } from "react";
import { Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";

import AuthService from "./services/auth.service";

import Login from "./components/Login";
import Home from "./components/Home";
import Profile from "./components/Profile";
import AppNavBar from "./components/AppNavBar";
import AppBottom from "./components/AppBottom";
import EventBus from "./common/EventBus";
import Snackbar from '@mui/material/Snackbar';

function PageWrapperAuthorized(props) {
	return (
		<div>
			<AppNavBar {...props} />
			<Snackbar
				vertical={'bottom'}
				horizontal={'center'}
				open={props.openSnackbar}
				autoHideDuration={5000}
				onClose={props.handleCloseSnackbar}
				message={props.messageSnackbar}
			/>
			<Outlet />
			<AppBottom />
		</div>
	);
}

function PageWrapperNotAuthorized(props) {
	return (
		<div>
			<Snackbar
				vertical={'bottom'}
				horizontal={'center'}
				open={props.openSnackbar}
				autoHideDuration={5000}
				onClose={props.handleCloseSnackbar}
				message={props.messageSnackbar}
			/>
			<Outlet />
			<AppBottom />
		</div>
	);
}

const App = () => {
	const [currentUser, setCurrentUser] = useState(undefined);
	const navigate = useNavigate();
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [messageSnackbar, setMessageSnackbar] = React.useState(false);

	const OpenSnackbar = (message) => {
		setOpenSnackbar(true);
		setMessageSnackbar(message);
	};

	const CloseSnackbar = (event, reason) => {
		setOpenSnackbar(false);
	};

	const logOut = useCallback(() => {
		AuthService.logout();
		setCurrentUser(undefined);
		navigate('/', { replace: true });
	}, [navigate]);

	useEffect(() => {
		const user = AuthService.getCurrentUser();
		setCurrentUser(user);

		EventBus.on("logout", () => {
			logOut();
		});

		console.log("update App");
		return () => {
			EventBus.remove("logout");
		};
	}, [logOut]);

	const GuestRoute = () => {
		return !currentUser ? (
			<PageWrapperNotAuthorized openSnackbar={openSnackbar} handleCloseSnackbar={CloseSnackbar} messageSnackbar={messageSnackbar} />
		) : (
			<Navigate to="/" replace />
		);
	};

	const ProtectedRoutes = () => {
		return currentUser ? (
			<PageWrapperAuthorized currentUser={currentUser} logOut={logOut} openSnackbar={openSnackbar} handleCloseSnackbar={CloseSnackbar} messageSnackbar={messageSnackbar} />
		) : (
			<Navigate to="/login" replace />
		);
	};

	return (
		<React.Fragment>
			<Routes>
				<Route element={<GuestRoute />}>
					<Route exact path="/login" element={<Login OpenSnackbar={OpenSnackbar} />} />
				</Route>

				<Route element={<ProtectedRoutes />}>
					<Route exact path="/" element={<Home currentUser={currentUser} OpenSnackbar={OpenSnackbar} />} />
					<Route exact path="/profile" element={<Profile currentUser={currentUser} OpenSnackbar={OpenSnackbar} />} />
				</Route>
			</Routes>
		</React.Fragment>
	);
};

export default App;
