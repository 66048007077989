import api from "./api";

const login = (username, password) => {
	return api.post("/Login/LoginAPI", {
		login: username,
		senha: password
	})
	.then((response) => {
		console.log(response.data);
		if (response?.data?.mensagem === "sucesso") {
			setUser(response.data);
			return true;
		}
		return false;
	});
};

const alterarSenha = (EmailUsuario, SenhaAtual, NovaSenha) => {
	return api.post("/Login/AlterarSenhaAPI", {
		EmailUsuario: EmailUsuario,
		SenhaAtual: SenhaAtual,
		NovaSenha: NovaSenha
	})
	.then((response) => {
		console.log(response.data);
		return response.data?.mensagem;
	});
};

const recuperarSenha = (email) => {
	return api.post("/Login/RecuperarSenhaAPI", {
		email: email
	})
	.then((response) => {
		console.log(response.data);
		return response.data?.mensagem;
	});
};

const logout = () => {
	removeUser();
};

const getCurrentUser = () => {
	var user = localStorage.getItem("user");
	return JSON.parse(user);
};

const setUser = (user) => {
	localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
	localStorage.removeItem("user");
};

const AuthService = {
	login,
	logout,
	setUser,
	removeUser,
	getCurrentUser,
	alterarSenha,
	recuperarSenha
};

export default AuthService;
