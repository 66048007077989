import React, {useCallback, useRef} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from "@mui/material/Box";
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import { Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dropzone from 'react-dropzone'

import arquivo from '../resources/arquivo.png';

const styles = {
	media: {
		height: "100%",
		width: "100%"
	},
	mediaSemArquivo: {
		height: "100%",
		width: "100%"
	},
	card: {
		width: '100%',
		position: 'relative',
		backgroundColor: 'transparent'
	},
	overlay: {
		position: 'relative',
		margin: 0,
		backgroundColor: 'transparent',
		fontSize: 14,
		fontWeight: 600
	},
	iconx: {
		position: 'absolute',
		top: '-5px',
		left: '65px'
	}
}

export default function DialogFiles(props) {
	const refInputFile = useRef();

	const resetInputFile = () => {
		refInputFile.current.value = "";
	  };

	const handleClose = (event, reason) => {
		event.preventDefault();
		if (reason && (reason === "backdropClick" || reason === "clickaway")) 
			return;

		resetInputFile();
		props.close();
	};

	const handleDeleteFile = (idxFile) => {
		props.handleDeleteFile(idxFile);
	};

	const handleDownloadFile = (idxFile) => {
		props.handleDownloadFile(idxFile);
	};

	const onDrop = useCallback(acceptedFiles => {
		props.handleAddFile(acceptedFiles);
	  }, [props])

	let elements_docs = [];
	if (props.anexos) {
		for (let [idx, anexoData] of props.anexos.entries()) {
			elements_docs.push(
				<Grid item key={"anexo" + idx} xs={4} md={3} sx={{ alignItems: 'center', width: '100%' }}>
					<Card sx={{ boxShadow: 0 }}>
						<CardActionArea onClick={() => handleDownloadFile(idx)}>
							<CardMedia style={styles.media} image={arquivo} component="img" />

							<Typography style={{ wordWrap: "break-word", alignItems: 'center', textAlign: 'center' }} sx={{ color: 'common' }}>
								{anexoData?.NomeArquivo}
							</Typography>
						</CardActionArea>
					</Card>


					<Grid container justifyContent="center" alignItems="center" spacing={0}>
						<IconButton sx={{ color: 'primary', mt: '1vmin', alignItems: 'center' }} fill="primary.main" aria-label="remover arquivo" onClick={() => handleDeleteFile(idx)}>
							<ClearIcon />
						</IconButton>
					</Grid>
				</Grid>
			);
		}
	}

	return (
		<React.Fragment>
			<Dialog
				fullWidth
				open={props.open}
				onClose={(event, reason) => handleClose(event, reason)}
			>
				<DialogTitle>Insira o(s) documento(s)</DialogTitle>
				<DialogContent>
					<Grid container justifyContent="center" alignItems="center" spacing={4}>
						{elements_docs}
					</Grid>

					<Dropzone onDrop={onDrop}>
						{({getRootProps, getInputProps}) => (
							<Paper
								elevation={6}
								{...getRootProps()}
								sx={{
									mt: '5vmin',
									minHeight: "15vh",
									width: "100%",
									boxShadow: 5,
									borderRadius: 2,
									backgroundColor: "rgba(0, 0, 0, 0.2)",
									backdropFilter: "blur(8px)",
									alignItems: 'center',
									textAlign: 'center' 
								}}
							>
								<Box sx={{ p: 1 }}>
									<input {...getInputProps()} ref={refInputFile} />
									<Typography style={{ wordWrap: "break-word", alignItems: 'center', textAlign: 'center' }} sx={{ color: 'common' }}>
										Arraste o arquivo aqui ou clique para selecionar
									</Typography>
									
								</Box>
								<Box sx={{ p: 1 }}>
									<CloudUploadIcon sx={{ fontSize: '60px' }} />
								</Box>
							</Paper>
						)}
					</Dropzone>
						
				</DialogContent>
				<DialogActions>
					<Button fullWidth sx={{ margin: "2vmin" }} onClick={handleClose}>Fechar</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}