import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { HashRouter } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import theme from './theme';
import App from "./App";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

// ReactDOM.render(
//   <BrowserRouter>
//     <CssBaseline />
//     <App />
//   </BrowserRouter>,
//   document.getElementById("root")
// );

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <HashRouter>
      <App />
    </HashRouter>
  </ThemeProvider>,
);
