import axios from "axios";

const instance = axios.create({
    //baseURL: "http://localhost:60393/",
    baseURL: "https://adm-afin.vexia.com.br/",
    headers: {
        'Content-Type': 'application/json'
    },
});


export default instance;
