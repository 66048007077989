//import { blue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

const colorPrimary = '#F0141E';
const colorPrimaryHover = '#f66f76';
const colorSecondary = '#FFFFFF';
const colorWarning = '#E5C42D';
const colorError = '#C3C3C3';
const colorHover = '#f33f48';
const colorCommon = '#191919';
const colorSuccess = '#28C600';
const colorSuccessHover = '#2ee600';
const colorNone = '#C3C3C3';

// A custom theme for this app
const theme = createTheme({
	palette: {
		primary: {
			main: colorPrimary,
			hover: colorPrimaryHover
		},
		secondary: {
			main: colorSecondary
		},
		warning: {
			main: colorWarning
		},
		error: {
			main: colorError
		},
		common: {
			main: colorCommon
		},
		success: {
			main: colorSuccess,
			hover: colorSuccessHover
		},
		none: {
			main: colorNone
		}
	},
	components:
	{
		MuiTextField: {
			styleOverrides: {
				root: {
					border: "0px solid",
					borderColor: colorCommon,
					borderRadius: '12px',
					'& .MuiOutlinedInput-root': {
						'& fieldset': {
							border: "2px solid",
							borderColor: colorCommon,
							borderRadius: '12px'
						},
						'&:hover fieldset': {
							borderColor: colorCommon,
						},
						'&.Mui-focused fieldset': {
							borderColor: colorCommon,
						}
					}
				}
			}
		},
		MuiButton:
		{
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.variant === 'success' ? {
						backgroundColor: colorSuccess,
						color: colorSecondary,
					} : {
						backgroundColor: colorPrimary,
						color: colorSecondary,
					}),
					...(ownerState.selected === true && {
						backgroundColor: colorSuccess,
						color: colorSecondary
					}),
					...(ownerState.selected === false && {
						backgroundColor: colorSecondary,
						color: colorPrimary
					}),
					padding: '1vmin',
					fontSize: '24px',
					borderRadius: '12px',
					"&:hover": {
						...(ownerState.variant === 'success' ? {
							backgroundColor: colorSuccessHover,
							color: colorSecondary,
						} : {
							backgroundColor: colorHover,
							color: colorSecondary,
						})
					},
					"&:disabled": {
						backgroundColor: colorError,
						color: colorSecondary
					}
				}),
			}
		}
	}
});

export default theme;
